.about-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
	position: relative;
	z-index: 2;
}

.about-logo {
	display: flex !important;
	position: fixed !important;
	/* border: 1px solid white; */
	border-radius: 50%;
	/* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25); */
	top: 4vh;
	z-index: 999;
}

.about-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 120px;
}

.about-main {
	display: flex;
}

.about-right-side {
	flex-basis: 900px;
}

.about-title {
	width: 100% !important;
}

.about-subtitle {
	width: 80% !important;
}

.skils-title {
	width: 80%;
	color: var(--primary-color);
	font-family: var(--secondary-font);
	font-size: 30px;
	font-weight: 700;
	margin: 30px 0 30px 0;
	text-transform: capitalize;
}

.about-skills {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-start;
	gap: 25px;
	margin-bottom: 20px;
}

.about-skill {
	position: relative;
	z-index: 1;
	margin-bottom: 1rem;
}

.about-skill-icon {
	width: 50px;
	height: 50px;
	cursor: pointer;
	transition: all 0.3s ease;
}

.about-skill-name {
	position: absolute;
	top: -30px;
	left: 50%;
	transform: translate(-50%, -50%);
	color: var(--primary-color);
	font-family: var(--primary-font);
	font-size: 15px;
	font-weight: 600;
	opacity: 0;
	transition: all 0.3s ease;
	background-color: rgba(85, 85, 85, 0.219);
	backdrop-filter: blur(10px);
	border-radius: 10px;
	padding: 5px 10px;
	width: max-content;
}

.name-bottom-arrow {
	position: absolute;
	top: -10px;
	left: 50%;
	transform: translate(-50%, -50%);
	transition: all 0.3s ease;
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 10px solid rgba(85, 85, 85, 0.219);
	opacity: 0;
}

.about-skill:hover .about-skill-name,
.about-skill:hover .name-bottom-arrow {
	opacity: 1;
}



.about-left-side {
	display: flex;
	flex-direction: column;
	flex-basis: 300px;
}

.about-image-container {
	width: 370px;
	height: 420px;
}

.about-image-wrapper {
	display: inline-block;
	overflow: hidden;
	width: 100%;
	height: 100%;
	border-radius: 10%;
	transform: rotate(3deg);
}

.about-image-wrapper img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: bottom;
}

.about-socials {
	padding-top: 50px;
	padding-bottom: 50px;
}

.about-socials-mobile {
	display: none;
}

.social a {
	padding: 5px 12px;
	border-radius: 8px;
	transition: all 0.3s ease;
}

.social a:hover {
	background-color: var(--quaternary-color);
	color: white;
}


@media (max-width: 1024px) {
	.about-logo {
		z-index: 100;
	}

	.about-main {
		flex-direction: column;
	}

	.about-subtitle {
		padding-top: 20px !important;
	}

	.about-left-side {
		flex-basis: 100%;
		order: 1;
	}

	.about-right-side {
		flex-basis: 100%;
		order: 2;
	}

	.about-image-container {
		padding-bottom: 40px;
	}

	.about-socials {
		display: none;
	}

	.about-socials-mobile {
		padding-top: 30px;
		display: flex;
	}
}

@media (max-width: 600px) {
	.about-container {
		padding-top: 60px;
	}

	.about-image-container {
		width: 100%;
	}

	.about-skill-name {
		font-size: 13px;
		top: -28px;
	}

	.about-skill:focus .about-skill-name,
	.about-skill:focus .name-bottom-arrow {
		opacity: 1;
	}

	.text-m-center {
		text-align: center;
	}

	.about-logo {
		z-index: 1 !important;
		top: 2.5vh;
	}

	.about-logo-container {
		z-index: 1;
	}

	.about-subtitle {
		width: 100% !important;
	}
}

@media screen and (min-width: 1550px) {
	.about-skill-icon {
		width: 57px;
		height: 75px;
	}

}