.contact-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.contact-logo {
	display: flex;
	position: fixed;
	/* border: 1px solid white; */
	border-radius: 50%;
	/* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25); */
	top: 4vh;
	z-index: 2;
}

.contact-subtitle {
	width: 95% !important;
}

.contact-container {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin-top: 120px;
}


.socials-container {
	display: flex;
	flex-direction: column;
	margin-top: 80px;
}

.contact-socials {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

@media screen and (max-width: 600px) {
	.contact-logo {
		z-index: 1;
	}

	.contact-container {
		margin-top: 60px;
	}
}