@import "../../../data/styles.css";

.nav-container {
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	z-index: 2;
}

.navbar {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed !important;
	top: 3vh;
	z-index: 995;
}

.nav-background {
	width: 380px;
	height: 40px;
	padding-left: 0%;
	padding-right: 0%;
	background: #fff;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
	border-radius: 40px;
}

.nav-list {
	display: flex;
	justify-content: space-between;
	list-style: none;
	align-items: center;
	margin-left: -16px;
	margin-right: 20px;
	margin-top: 11px;
}

.nav-item {
	font-weight: bold;
	font-size: 80%;
}

.nav-item.active a {
	color: var(--link-color) !important;
}

.nav-item a {
	text-decoration: none;
	color: var(--primary-color);
}

.nav-item a:hover {
	color: var(--link-color);
	transition: color 0.3s ease-in-out;
}

.toggle-nav {
	display: none;
}

@media (max-width: 600px) {
	.navbar {
		margin-left: 10%;
		margin-right: 25%;
		width: 100%;
		font-size: 80%;
		transform: translateX(500px);
		transition: transform 0.5s ease-in-out;
	}

	.navbar-open {
		transform: translateX(0px);
		transition: transform 0.5s ease-in-out;
	}

	.nav-background {
		width: 80%;
		height: 40px;
	}

	.nav-item {
		font-weight: bold;
	}

	.toggle-nav {
		display: flex;
		justify-content: center;
		align-items: center;
		position: fixed !important;
		top: 3vh;
		right: 4vh;
		z-index: 999;
		cursor: pointer;
		background-color: var(--primary-color);
		border-radius: 50%;
		width: 40px;
		height: 40px;
		transition: all 0.3s ease-in-out;
	}

	.toggle-nav svg {
		color: #fff;
		transition: all 0.3s ease-in-out;
	}
}

@media screen and (max-width: 450px) {
	.toggle-nav {
		right: 2vh;
		transition: all 0.3s ease-in-out;
	}
}

.rotate {
	transform: rotate(180deg);
	transition: all 0.3s ease-in-out;
}